// import logo from './logo.svg';
import photo from './images/photo.jpg'
import sep from './images/Sep.jpg'
import twitter from './images/twitter.svg'
import linkedin from './images/linkedin.svg'
import google from './images/google.svg'

import './App.css';

function App() {
  return (
    <div className="App">
      <nav>
        <a href="/about" className="nav--feature">Home</a>
        <a href="/home" className="nav--feature">About</a>
        <a href="/blog" className="nav--feature">Blog</a>
      </nav>
      <header className="App-header">
          <img src={photo} className="App-logo" alt="logo" />
          <p className="title">
            Hey, I'm Umang
          </p>
          <p className="sub-title">
            Software Developer | Startup Ethusiast | Poet
          </p>
      </header>
      <div className="icons">
        <a href="https://twitter.com/umangjalan23"><img className="icon" src={twitter}  alt="twitter"/></a>
        <a href="https://www.instagram.com/uj_umang/"><span className="iconify icon" data-icon="ant-design:instagram-filled"></span></a>
        <a href="mailto:hi@umangjalan.com"><img className="icon" src={google} alt="google" /></a>
        <a href="https://www.linkedin.com/in/umangjalan/"><img className="icon" src={linkedin} alt="linkedin"  /></a>
      </div>
      <img className="sep" src={sep}  alt="separator"/>

    </div>
  );
}

export default App;
